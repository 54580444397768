import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Link, FormattedMessage, injectIntl } from "gatsby-plugin-intl"
import Img from "gatsby-image"

import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Button from "react-bootstrap/Button"

import FadeIn from "react-fade-in"

import EventItem from "../components/events/eventItem"
import Layout from "../components/layout"
import SEO from "../components/seo"

const Events = ({ intl }) => {
  const data = useStaticQuery(graphql`
    query {
      allStrapiKoEvent {
        edges {
          node {
            strapiId
            title
            summary
            date
            location
            content
            thumbnail {
              childImageSharp {
                fixed(height: 95, width: 95) {
                  ...GatsbyImageSharpFixed_withWebp
                }
              }
            }
          }
        }
      }
      allStrapiEnEvent {
        edges {
          node {
            strapiId
            title
            summary
            date
            location
            content
            thumbnail {
              childImageSharp {
                fixed(height: 95, width: 95) {
                  ...GatsbyImageSharpFixed_withWebp
                }
              }
            }
          }
        }
      }
      allStrapiCnEvent {
        edges {
          node {
            strapiId
            title
            summary
            date
            location
            content
            thumbnail {
              childImageSharp {
                fixed(height: 95, width: 95) {
                  ...GatsbyImageSharpFixed_withWebp
                }
              }
            }
          }
        }
      }
      allStrapiKoPage(filter: { url: { eq: "/events" } }) {
        edges {
          node {
            url
            title
            subtitle
          }
        }
      }
      allStrapiEnPage(filter: { url: { eq: "/events" } }) {
        edges {
          node {
            url
            title
            subtitle
          }
        }
      }
      allStrapiCnPage(filter: { url: { eq: "/events" } }) {
        edges {
          node {
            url
            title
            subtitle
          }
        }
      }
    }
  `)

  let strapiEdges = []
  let strapiNode
  switch (intl.locale) {
    case "en":
      strapiEdges = data.allStrapiEnEvent.edges
      strapiNode = data.allStrapiEnPage.edges[0].node
      break
    case "ko":
      strapiEdges = data.allStrapiKoEvent.edges
      strapiNode = data.allStrapiKoPage.edges[0].node
      break
    case "cn":
      strapiEdges = data.allStrapiCnEvent.edges
      strapiNode = data.allStrapiCnPage.edges[0].node
      break
    default:
      break
  }

  const sortFn = (a, b) => {
    return new Date(b.node.date).getTime() - new Date(a.node.date).getTime()
  }

  strapiEdges.sort(sortFn)

  let events = <></>
  if (strapiEdges.length) {
    events = strapiEdges.map(event => (
      <EventItem
        key={event.node.strapiId}
        strapiId={event.node.strapiId}
        title={event.node.title}
        date={event.node.date}
        location={event.node.location}
        summary={event.node.summary}
        content={event.node.content}
        thumbnail={event.node.thumbnail}
      />
    ))
  }

  return (
    <Layout page="events">
      <SEO lang={intl.locale} title={strapiNode.title} />
      <Container fluid>
        <FadeIn>
          <h1 className="text-center">{strapiNode.title}</h1>
          <p className="text-center">{strapiNode.subtitle}</p>
          {events}
        </FadeIn>
      </Container>
    </Layout>
  )
}

export default injectIntl(Events)
